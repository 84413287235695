var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show != null
    ? _c("div", { staticClass: "component-loader" }, [
        _vm.show
          ? _c("div", { staticClass: "container-loader" }, [
              _c("div", { staticClass: "loader" }),
            ])
          : _c("h6", { staticClass: "search-data" }, [
              _vm._v("Buscando información ..."),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }