<template>
  <div>
    <vs-popup background-color="rgba(255,255,255,.6)"  class=""  title="Proceso" :active.sync="popupShowActive">
      <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
        <div class="vx-card p-2">
          <ProjectInformation :project="project"/>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import ProjectInformation from './ProjectInformation.vue'

export default {
  props: {
    popupShowActive: Boolean,
    project: {},
  },
  data (){
    return{
      // project: this.$store.getters['todo/getProject'],
      colorx:"rgba(255,255,255,255)"
    }
  },
  methods: {

  },
  created(){

  },
  mounted(){

  },
  components:{
    ProjectInformation
  }
}
</script>

<style>
  .vs-popup{
    width: 80% !important;
  }
</style>