<template>
  <div class="component-loader" v-if="show != null">
    <div class="container-loader" v-if="show">
      <div class="loader"></div>
    </div>        
    <h6 class="search-data" v-else>Buscando información ...</h6>
  </div>
</template>

<script>
  export default {
    props: {
      show: null
    }
  }
</script>

<style scoped>
  .container-loader, .component-loader {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
  }
  .search-data {
    color: rgb(95, 0, 145);
  }
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid rgb(95, 0, 145); /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>